<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="mx-2 mb-2 mt-0">
      <b-row class="justify-content-end">
        <b-col
          cols="12"
          md="4"
          lg="2"
        >
          <label for="begin-date">Início em</label>
          <b-form-datepicker
            id="begin-date"
            v-model="beginDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt-BR"
            label-help=""
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          lg="2"
        >
          <label for="end-date">Fim em</label>
          <b-form-datepicker
            id="end-date"
            v-model="endDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="pt-BR"
            label-help=""
          />
        </b-col>
        <b-col
          cols="12"
          md="12"
          lg="8"
        >
          <b-row>
            <b-col
              cols="12"
              md="3"
            >
              <label for="entity-id">Entidade</label>
              <v-select
                id="entity-id"
                v-model="entityId"
                name="entity-id"
                :clearable="false"
                :options="entitiesOptions"
                label="label"
                item-value="value"
                item-text="label"
              />
            </b-col>
            <b-col
              cols="12"
              md="5"
            >
              <label for="institution-id">Instituição</label>
              <v-select
                id="institution-id"
                v-model="institutionId"
                name="institution-id"
                :options="institutionOptions"
                label="label"
                item-value="value"
                item-text="label"
              >
                <span slot="no-options">Nenhuma instituição encontrada.</span>
              </v-select>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <div class="d-flex w-100">
                <div class="flex-grow-1">
                  <label for="user-id">Usuário</label>
                  <v-select
                    id="user-id"
                    v-model="userId"
                    name="user-id"
                    :options="userOptions"
                    label="label"
                    item-value="value"
                    item-text="label"
                  >
                    <template #option="{ value: { name, email, user_type } }">
                      <div class="font-weight-bold">
                        {{ name }}
                      </div>
                      <div class="user-email">
                        <span>{{ email }}</span>
                      </div>
                      <div class="user-type">
                        <span class="user-type-label">Tipo: </span>
                        <span class="user-type-value">{{ user_type === 'admin' ? 'Admin' : 'Cliente' }}</span>
                      </div>
                    </template>
                    <span slot="no-options">Nenhuma instituição encontrada.</span>
                  </v-select>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refInstitutionListTable"
      :items="fetchAudits"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Nenhum registro correspondente encontrado"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <template #cell(type)="data">
        <b-badge
          pill
          :variant="`light-${resolveTypeVariant(data.item.auditable.type)}`"
          class="text-capitalize"
        >
          {{ data.item.auditable.type_translated }}
        </b-badge>
      </template>
      <template #cell(event)="data">
        <b-badge
          pill
          :variant="`light-${resolveEventVariant(data.item.event)}`"
          class="text-capitalize"
        >
          {{ data.item.event_translated }}
        </b-badge>
      </template>
      <template #cell(created_at)="data">
        {{ formatDate(new Date(data.item.created_at), 'dd/MM/yy HH:mm:ss') }}
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-link
            variant="primary"
            @click="view(data)"
          >
            <feather-icon
              :id="`transaction-row-${data.item.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1"
            />
          </b-link>
          <!--          <feather-icon-->
          <!--            :id="`link-${data.item.id}-icon`"-->
          <!--            icon="ExternalLinkIcon"-->
          <!--            size="16"-->
          <!--          />-->
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Exibir</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entradas</label>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>
      </b-row>
    </div>

    <b-modal
      id="modal-center"
      centered
      :title="`Registro de Auditoria #${audit && audit.id}`"
      hide-footer
      :size="audit && audit.auditable.type === 'Invoice' ? 'xl': 'lg'"
    >
      <template v-if="audit">
        <Institution
          v-if="audit.auditable.type === 'Institution'"
          :audit="audit"
        />
        <FinancialInstitution
          v-if="audit.auditable.type === 'FinancialInstitution'"
          :audit="audit"
        />
        <PhysicianDuty
          v-if="audit.auditable.type === 'PhysicianDuty' && audit.event !== 'physician-duty.canceled-advanced'"
          :audit="audit"
        />
        <Physician
          v-if="audit.auditable.type === 'Physician'"
          :audit="audit"
        />
        <PaymentRemittance
          v-if="audit.auditable.type === 'PaymentRemittance'"
          :audit="audit"
        />
        <Invoice
          v-if="audit.auditable.type === 'Invoice'"
          :audit="audit"
        />
        <Workplace
          v-if="audit.auditable.type === 'Workplace'"
          :audit="audit"
        />
        <User
          v-if="audit.auditable.type === 'User'"
          :audit="audit"
        />
        <PhysicianDutyCanceledAdvanced
          v-if="audit.event === 'physician-duty.canceled-advanced'"
          :audit="audit"
        />
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BBadge, BModal, BLink, BFormDatepicker, BPagination,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import { format } from 'date-fns'
import vSelect from 'vue-select'
import { maskCnpj } from '@core/utils/utils'
import Invoice from '@/views/audit/audit-list/views/Invoice.vue'
import useAuditList from './useAuditList'
import auditStoreModule from '../auditStoreModule'
import Institution from './views/Institution.vue'
import FinancialInstitution from './views/FinancialInstitution.vue'
import PhysicianDuty from './views/PhysicianDuty.vue'
import Physician from './views/Physician.vue'
import PaymentRemittance from './views/PaymentRemittance.vue'
import PhysicianDutyCanceledAdvanced from './views/PhysicianDutyCanceledAdvanced.vue'
import Workplace from './views/Workplace.vue'
import User from './views/User.vue'

export default {
  components: {
    BPagination,
    BCard,
    BRow,
    BCol,
    vSelect,
    BTable,
    BBadge,
    BModal,
    BLink,
    BFormDatepicker,
    Institution,
    FinancialInstitution,
    PhysicianDuty,
    Physician,
    PaymentRemittance,
    PhysicianDutyCanceledAdvanced,
    Invoice,
    User,
    Workplace,
  },
  setup() {
    const AUDITS_APP_STORE_MODULE_NAME = 'app-audits'

    // Register module
    if (!store.hasModule(AUDITS_APP_STORE_MODULE_NAME)) store.registerModule(AUDITS_APP_STORE_MODULE_NAME, auditStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUDITS_APP_STORE_MODULE_NAME)) store.unregisterModule(AUDITS_APP_STORE_MODULE_NAME)
    })

    const audit = ref(null)

    const {
      fetchAudits,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInstitutionListTable,
      refetchData,
      resolveTypeVariant,
      resolveEventVariant,
      beginDate,
      endDate,
      institutionId,
      institutionOptions,
      entityId,
      entitiesOptions,
      userId,
      userOptions,
    } = useAuditList()

    function view(value) {
      audit.value = value.item
      this.$bvModal.show('modal-center')
    }

    store.dispatch('app-audits/fetchUsers', { perPage: 99999999 })
      .then(response => {
        userOptions.value = response.data.data.map(user => ({
          value: user,
          label: `${user.name}`,
        }))
      })

    store.dispatch('app-audits/fetchInstitutions', { perPage: 99999999 })
      .then(response => {
        institutionOptions.value = response.data.data.map(inst => ({
          value: inst.id,
          label: `${inst.name} | ${maskCnpj(inst.cnpj)}`,
        }))
      })

    return {
      view,
      audit,
      userId,
      userOptions,
      institutionId,
      beginDate,
      endDate,
      institutionOptions,
      entityId,
      entitiesOptions,
      resolveEventVariant,
      fetchAudits,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInstitutionListTable,
      formatDate: format,
      refetchData,
      resolveTypeVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.per-page-selector {
  width: 90px;
}

.transaction-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.user-email {
  font-size: 12px;
}
.user-type {
  font-size: 12px;
  .user-type-label {
    color: #5e5873;
  }
  .user-type-value {
    font-weight: bold!important;
  }
}
</style>
